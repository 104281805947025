import { InStockIcon } from '@app/_icons/InStockIcon';

type Props = {
    show: boolean;
    className?: string;
    soonBackInStock: boolean;
};

export default function StockStatus({
    show,
    className,
    soonBackInStock,
}: Props) {
    if (!show) return null;

    return (
        <span className={soonBackInStock ? 'text-warning' : 'text-green'}>
            <InStockIcon className={className} />
        </span>
    );
}
